import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { useTheme } from "@ryerson/frontend.theme";
import { useApplication } from "@ryerson/frontend.application";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { Modal } from "@ryerson/frontend.modal";
import { Icon } from "@ryerson/frontend.assets";
import { withTranslation, SwapAccount, SwapAccountMobile } from "@ryerson/frontend.header-footer";
import { ApiUrl } from "@ryerson/frontend.common";

import {
	accountMenuData,
	AccountMenuItem,
	AccountMenuItemId,
	checkMenuItemPermission,
} from "@ryerson/frontend.header-footer";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";

export interface MyAccountMenuProps {
	className?: string;
	isMobile: boolean;
	isHeroPage?: boolean;
}

const Divider = styled.hr`
	border-top: 1px solid ${(props) => props.color};
	opacity: 0.1;
`;
const MenuItemSection = styled(FlexItem)<any>`
	margin: 20px 0;
	width: ${(props) => (props.isMobile ? "100%" : "232px")};
`;

const menuItemSection = css`
	margin: 5px 0;
	width: 100%;
`;

const menuWrapper = (props: any) => css`
	background-color: ${props.background};
	padding: 20px;
	box-shadow: ${props.shadow ||
	`0px 100px 80px rgba(0, 0, 0, 0.07), 0px 0px 17.869px rgba(0, 0, 0, 0.0417275),
		0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);`};
`;

const menuItemStyles = (color: string, hoverColor: string) => css`
	margin-bottom: 15px;
	cursor: inherit;
	color: ${color};
	:hover {
		color: ${hoverColor};
		& span:first-of-type {
			color: ${hoverColor};
		}
	}
`;

const SwapAccountWrapper = styled(Flex)`
	.swap-account.slide-open,
	.swap-account.slide-close {
		top: 0px !important;
	}
`;

const MyAccountDashboardMenu: React.FC<MyAccountMenuProps> = ({
	className = "",
	isMobile,
	isHeroPage = false,
}) => {
	const {
		logout,
		profile,
		user: { token },
		localization: { language },
		axiosInstance,
	} = useApplication();

	const [hasMounted, setHasMounted] = React.useState<boolean>(false);

	// MyAccount Menu
	const myAccountMenuHeroPageItems: Array<AccountMenuItemId> = [
		AccountMenuItemId.dashboard,
		AccountMenuItemId.orders,
		AccountMenuItemId.shoppingList,
		AccountMenuItemId.quotes,
		AccountMenuItemId.invoices,
	];
	const myAccountFooterHeroPageItems = [AccountMenuItemId.switchAccounts];
	const myAccountMenuDefaultItems: Array<AccountMenuItemId> = [
		AccountMenuItemId.dashboard,
		AccountMenuItemId.orders,
		AccountMenuItemId.subscribeSave,
		AccountMenuItemId.shoppingList,
		AccountMenuItemId.quotes,
		AccountMenuItemId.invoices,
		AccountMenuItemId.documentCenter,
		AccountMenuItemId.reports,
		AccountMenuItemId.claimsReturns,
		AccountMenuItemId.accountSettings,
		AccountMenuItemId.creditApplication,
		AccountMenuItemId.userManagement,
	];
	const myAccountFooterDefaultItems = [
		AccountMenuItemId.adminPortal,
		AccountMenuItemId.switchAccounts,
		AccountMenuItemId.logOut,
	];
	const [menuList, setMenuList] = useState<Array<AccountMenuItem>>([]);
	const [menuFooter, setFooterList] = useState<Array<AccountMenuItem>>([]);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [switchAccountModalTitle, setSwitchAccountTitle] = useState<string>("");
	const { theme } = useTheme();
	const {
		primary: { white, primaryBrand, secondaryBrand },
		tertiary: { link, disable },
	} = theme.colors;
	const location = useLocation();

	function translateMenu(id: string = "") {
		const hasLabel = !!withTranslation[id];
		return hasLabel ? withTranslation[id][language] : false;
	}

	// Build Menu List Footer Items from Default Footer Items & Filters Items Based on Permission
	function buildMenuList(): Array<AccountMenuItem> {
		let defaultMenuList: AccountMenuItem[] = [];
		for (let id of isHeroPage ? myAccountMenuHeroPageItems : myAccountMenuDefaultItems) {
			let foundItem = accountMenuData.find((itm) => itm.id === id);
			const isEnabled = foundItem;
			let permission = foundItem && checkMenuItemPermission(foundItem.id, token);

			let newItem: any = {
				...foundItem,
				...{ label: translateMenu(foundItem?.id) || foundItem?.label },
			};

			foundItem && isEnabled && permission && defaultMenuList.push(newItem);
		}

		return defaultMenuList;
	}
	//Build Menu List Footer Items from Default Menu Items & Filters Items Based on Permission
	function buildFooterList(): Array<AccountMenuItem> {
		let defaultFooterList: AccountMenuItem[] = [];
		/**
		 * Don’t display any footer options until the user’s profile has been fetched.
		 * This is because not every user has access to every footer option.
		 * (e.g. «switch accounts»)
		 */
		if (profile === null) {
			return defaultFooterList;
		}
		for (let id of isHeroPage ? myAccountFooterHeroPageItems : myAccountFooterDefaultItems) {
			let foundItem = accountMenuData.find((itm) => itm.id === id);
			let permission = foundItem && checkMenuItemPermission(foundItem.id, token);
			let newItem: any = {
				...foundItem,
				...{ label: translateMenu(foundItem?.id) || foundItem?.label },
			};

			foundItem && permission && defaultFooterList.push(newItem);
		}
		return defaultFooterList;
	}

	useEffect(() => {
		if (hasMounted) {
			setMenuList(buildMenuList());
			setFooterList(buildFooterList());
		}
	}, [hasMounted, language]);

	useEffect(() => {
		setHasMounted(true);
	}, []);

	const onLogoutClick = () => {
		logout();
		navigate(location.origin + "/login");
	};

	const onSwitchAccountsClick = (id: AccountMenuItemId): void => {
		setSwitchAccountTitle(withTranslation[id][language]);
		setModalOpen(true);
	};

	const getAPIData = (
		url: ApiUrl,
		successCallback: (data: any) => void,
		errorCallBack?: (data: any) => void
	) => {
		axiosInstance
			.post(url, {}, {})
			.then((response: any) => {
				const { status = "", data = "" } = response || {};
				if (status === 200) {
					successCallback && successCallback(data);
				} else {
					throw new Error(`${status}`);
				}
			})
			.catch((error) => {
				console.error("~Error", error);
				errorCallBack && errorCallBack(error);
			});
	};

	const onMenuItemClick = (item: AccountMenuItem) => {
		const { id, url, active, label } = item;

		switch (true) {
			case id === AccountMenuItemId.logOut:
				onLogoutClick();
				break;
			case id === AccountMenuItemId.switchAccounts:
				onSwitchAccountsClick(id);
				break;
			case id === AccountMenuItemId.adminPortal:
				getAPIData(ApiUrl.MAGENIUM_PORTAL, (targetUrl) => (window.location = targetUrl));
				break;
			default:
				if (active && !!(url.length > 0)) {
					navigate("/store" + item.url);
				} else {
					console.error("*disabled-item", label);
				}
				break;
		}
	};

	const renderMenuItem = (item: AccountMenuItem, index: number): JSX.Element => {
		const menuItemDisabled = item.active === false;

		const url = new URL(window.location.href);
		let defaultColor: string;
		if (item.url !== "" && new RegExp(`${item.url}\\/*?$`).test(url.pathname)) {
			defaultColor = primaryBrand;
		} else if (menuItemDisabled) {
			defaultColor = disable;
		} else {
			defaultColor = link;
		}
		const defaultHoverColor = menuItemDisabled ? disable : primaryBrand;

		const itemStyle = {
			cursor: !menuItemDisabled ? "pointer" : "default",
			opacity: menuItemDisabled ? ".5" : "1",
		};
		return (
			<Flex
				onClick={() => onMenuItemClick(item)}
				alignItems="center"
				key={index}
				style={menuItemStyles(defaultColor, defaultHoverColor)}
				dataTestId={`menu-list-${item.label}`}
			>
				{/* <Icon size="xs" icon={item.icon} color={item.active ? primaryBrand : lightGray} /> */}
				<Icon size="xs" icon={item.icon} color={defaultColor} css={itemStyle} />
				<FlexItem css={{ marginLeft: 20 }}>
					<Typography
						size={isMobile ? "md" : "sm"}
						variant="div"
						color={menuItemDisabled ? disable : "inherit"}
						css={itemStyle}
					>
						{item.label}
					</Typography>
				</FlexItem>
			</Flex>
		);
	};

	const okToRenderFooterItem = (itemId: AccountMenuItemId) => {
		if (itemId === AccountMenuItemId.switchAccounts && profile?.groups?.length === 1) {
			return false;
		} else {
			return true;
		}
	};

	return (
		<>
			<Media lessThan="lg">
				{profile === null ? (
					<></>
				) : (
					<>
						<div
							css={{
								backgroundColor: secondaryBrand,
								width: "100%",
								position: "relative",
							}}
						>
							<Accordion variant="box" multiple={true} type="tertiary">
								<AccordionTab
									header={"My Account"}
									circled={true}
									type="tertiary"
									defaultOpen={true}
									tabIndex={1}
									maxHeight="100%"
								>
									<Flex
										style={menuWrapper({
											breakpoint: theme.breakpoints.lg,
											background: theme.colors.primary.secondaryBrand,
											shadow: "unset",
										})}
									>
										<FlexItem style={menuItemSection}>
											{menuList.map((item: AccountMenuItem, index: number) =>
												renderMenuItem(item, index)
											)}
											{profile?.groups?.length > 1 && (
												<Divider
													color={white}
													css={{ margin: "20px 30px 20px 0" }}
												/>
											)}

											{menuFooter.map(
												(footerItem: AccountMenuItem, index: number) => {
													return (
														okToRenderFooterItem(footerItem.id) &&
														renderMenuItem(footerItem, index)
													);
												}
											)}
										</FlexItem>
									</Flex>
								</AccordionTab>
							</Accordion>
						</div>
						<SwapAccountWrapper>
							<SwapAccountMobile
								handleClose={() => setModalOpen(false)}
								openSwapAccount={modalOpen}
							/>
						</SwapAccountWrapper>
					</>
				)}
			</Media>
			<Media greaterThanOrEqual="lg">
				<Flex
					className={className}
					direction="column"
					style={css`
						margin-top: 15px;
					`}
				>
					<FlexItem>
						<Typography variant="h4" type="tertiary" css={{ margin: 0 }}>
							My Account
						</Typography>
						<Divider color={white} css={{ margin: "20px 30px 0 0" }} />
					</FlexItem>
					<MenuItemSection isMobile={isMobile} dataTestId="menu-item-section">
						{menuList.map((item: AccountMenuItem, index: number) =>
							renderMenuItem(item, index)
						)}
						{profile?.groups?.length > 1 && (
							<Divider color={white} css={{ margin: "20px 30px 20px 0" }} />
						)}

						{menuFooter.map((footerItem: AccountMenuItem, index: number) => {
							return (
								okToRenderFooterItem(footerItem.id) &&
								renderMenuItem(footerItem, index)
							);
						})}
					</MenuItemSection>
				</Flex>
				<Modal
					title={switchAccountModalTitle}
					closable={true}
					isOpen={modalOpen}
					onClose={() => setModalOpen(false)}
					dataTestId="menu-swap-accounts-modal"
				>
					<SwapAccount handleClose={() => setModalOpen(false)} isMobile={false} />
				</Modal>
			</Media>
		</>
	);
};

export default MyAccountDashboardMenu;
